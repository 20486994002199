* {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  outline: none !important;
}

.chinese * {
  font-family: "Ma Shan Zheng", cursive !important;
  i.las {
    font-family: "Line Awesome Free" !important;
  }
  i.lab {
    font-family: "Line Awesome Brands" !important;
  }
}

ul {
  list-style-type: none;
  line-height: 3rem;
  padding: 0;
  margin: 0;

  * {
    user-select: none;
  }
}

img {
  user-select: none;
}
