.home {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
  background-image: url('../../assets/images/hero-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-color: #6d2600;
}
.shadow-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.hello {
  font-size: 1.6rem;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: 600;
}

.description {
  font-size: 3.5rem;
  line-height: 1.5;
  margin-top: 0;
  color: #ffffff;
  font-weight: 600;
}

.logo {
  position: absolute;
  left: 15px;
  top: 7.5px;
  img {
    width: 80px;
    height: auto;
  }
}

.zindex-2 {
  z-index: 2;
}

.home-content {
  margin-top: 80px;
  max-width: 80%;
}

.home-social {
  font-family: "montserrat-regular", sans-serif;
  list-style: none;
  margin: 0;
  position: absolute;
  top: 50%;
  right: 1.25rem;
  transform: translate3d(0, -50%, 0);
}

.home-social a {
  color: white;
  transition: all 0.5s ease-in-out;

  :hover {
    color: white;
  }
}

.home-social li {
  position: relative;
  padding: 0.9rem 0;
}

.home-social li a {
  display: block;
  width: 33px;
  height: 33px;
}

.home-social i,
.home-social span {
  position: absolute;
  top: 0;
  line-height: 33px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.home-social i {
  font-size: 14px;
  right: 0;
  text-align: center;
  display: inline-block;
  width: 33px;
  height: 33px;
  line-height: calc(33px - 4px);
  border: 2px solid #ffffff;
  border-radius: 50%;
}

.home-social span {
  color: #f28e30;
  right: 2.5rem;
  font-size: 0.8rem;
  font-weight: 600;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

.home-social li:hover span {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.barretta-arancione {
  width: 2px;
  height: 100px;
  position: absolute;
  right: 70px;
  bottom: 0;
  background: #f28e30;
}

.scroll-button {
  position: absolute;
  right: 90px;
  color: white;
  bottom: 85px;
  font-weight: 700;
  font-size: 0.7rem;
  letter-spacing: 0.2rem;
  cursor: pointer;
  i {
    color: #f28e30;
    font-weight: bold;
  }
}

.barretta-bianca {
  width: 2px;
  height: 100px;
  position: absolute;
  right: 70px;
  top: 0;
  background: white;
}

.home-button {
  padding: 15px 35px;
  background: transparent;
  color: white;
  border: 2px solid white;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 2px;
  transition: all 0.5s ease-in-out;

  &:hover {
    background: white;
    color: black;
  }
}

#about {
  padding: 8rem 0;
  background-color: #f28e30;
  color: #010507;
  position: relative;

  h3 {
    font-size: 1rem;
    color: black;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
  }

  h1 {
    font-size: 5rem;
    color: white;
    font-weight: 600;
  }

  .separator {
    width: 60%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.25);
    margin: 30px auto;
  }

  p {
    font-size: 1.4rem;
    line-height: 1.75;
    text-align: center;
  }
}

#services {
  padding: 8rem 0;
  background-color: white;
  color: #010507;
  position: relative;

  .barretta-arancione {
    width: 2px;
    height: 100px;
    position: absolute;
    right: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    background: #f28e30;
  }

  h3 {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    color: #f28e30;
  }

  h1 {
    font-size: 3rem;
    font-weight: 600;
  }

  .separator {
    width: 60%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 30px auto;
  }

  .service-item {
    i {
      margin-right: 2rem;
      color: #f28e30;
      font-size: 4rem;
    }

    h3 {
      color: black;
      font-weight: 700;
      text-transform: none;
      font-size: 1.8rem;
      letter-spacing: 0;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1rem;
      color: #757575;

      ul {
        list-style-type: circle;
        margin-left: 2rem;
        line-height: 25px;
      }
    }
  }

  p {
    font-size: 1.4rem;
    line-height: 1.75;
  }
}

.ui.error.message {
  position: absolute;
  background-color: red;
  color: white;
  font-size: 12px;
  padding: 5px;
  border-radius: 0 10px 10px 0;
  display: none;
  top: 15px;
  left: 50%;

  &::before {
    content: "\A";
    border-style: solid;
    border-width: 15px 15px 15px 0;
    border-color: transparent #ff0018 transparent transparent;
    position: absolute;
    left: -15px;
    top: -1px;
  }
}

#contacts {
  width: 100%;
  background-image: url("../../assets/images/contact-bg.jpg");
  background-size: cover;
  position: relative;

  .message-warning,
  .message-success {
    display: none;
    background: #111111;
    font-size: 1.5rem;
    line-height: 2;
    padding: 3rem;
    margin-bottom: 3.6rem;
    width: 100%;
  }

  .message-warning {
    color: #ff6163;
  }

  .message-success {
    color: #f28e30;
  }

  .message-warning i,
  .message-success i {
    margin-right: 10px;
    font-size: 1.2rem;
  }

  fieldset {
    position: relative;
    margin-bottom: 15px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-color: #151515;
  }

  .container {
    padding-top: 140px;
  }

  .barretta-bianca {
    width: 2px;
    height: 100px;
    position: absolute;
    right: 50%;
    transform: translate(-50%, 0);
    top: 0;
    background: white;
  }

  h1 {
    font-size: 3.5rem;
    color: white;
    font-weight: 600;
  }

  h3 {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    color: #f28e30;
  }

  .form-container {
    background: rgba(0, 0, 0, 0.2);
    text-align: left;
    padding: 50px 40px;

    input,
    textarea,
    input:-internal-autofill-selected {
      width: 100%!important;
      border: none!important;
      background: transparent!important;
      color: #151515!important;
      padding-bottom: 10px!important;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15)!important;
      &:focus {
        outline: none!important;
        border-bottom: 1px solid #f28e30!important;
      }
      &::placeholder {
        font-size: 0.8rem!important;
        color: rgba(255, 255, 255, 0.15)!important;
      }

      &::-webkit-resizer {
        display: none!important;
      }
    }

    .input-error {
      color: #f28e30!important;
      font-size: 10px!important;
      position: absolute!important;
      left: 0!important;
      bottom: 0!important;
      opacity: 0!important;
      transition: opacity 0.3s ease-in-out!important;

      &.show {
        opacity: 1!important;
      }
    }

    .form-field {
      position: relative!important;
    }

    button {
      color: white;
      background-color: #f28e30;
      padding: 15px;
      border: none;
      border-radius: 0;
      letter-spacing: 0.18rem;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.8rem;
      outline: none;
      box-shadow: none;
      &:focus {
        outline: none;
      }
    }
  }
  .title {
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    font-size: 0.8rem;
  }
  .contact-container {
    background: rgba(0, 0, 0, 0.4);
    padding: 50px 40px;
    text-align: left;

    h6 {
      font-weight: 600;
      font-size: 1rem;
      color: #f28e30;
      margin-top: 60px;
    }

    p {
      color: rgba(255, 255, 255, 0.4);
      margin-bottom: 3rem;
      line-height: 2;
    }

    .social-icon {
      li {
        color: white;
        cursor: pointer;

        i {
          font-size: 1.5rem;
        }

        &:hover {
          color: #f28e30;
        }
      }
    }
  }
}

.footer {
  background: #111111;
  padding: 60px;
  text-align: center;
  color: rgba(255, 255, 255, 0.3);

  span {
    font-size: 0.8rem;

    &:first-child {
      &::after {
        content: "|";
        display: inline-block;
        padding: 0 0.5rem 0 0.6rem;
        color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  a {
    font-size: 0.8rem !important;
    color: white;
    transition: color 0.3s ease-in-out;
    &:hover {
      color: #f28e30;
      text-decoration: none;
    }
  }
}

.go-up {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 45px;
  height: 45px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
  background: black;
  color: gray;
  z-index: 999;
  opacity: 0;

  &:hover {
    color: white;
  }
  i {
    font-size: 24px;
  }
}

@media only screen and (max-width: 1200px) {
  .hello {
    font-size: 1.4rem;
  }
  .description {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 900px) {
  .hello {
    font-size: 1rem;
  }
  .description {
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 800px) {
  .home-content {
    text-align: center;
  }

  .hello {
    font-size: 0.7rem;
  }
  .description {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 767px) {
  #services {
    .service-item {
      i {
        margin-right: 0 !important;
      }

      p {
        ul {
          text-align: left;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .home-social {
    display: none;
  }

  .home-content {
    text-align: center;
    max-width: 90%;
  }

  .hello {
    font-size: 0.7rem;
  }
  .description {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .home-button {
    width: 100%;
  }

  #about {
    h1 {
      font-size: 2.5rem;
    }

    p {
      font-size: 1rem;
    }
  }

  .barretta-arancione {
    height: 70px;
  }

  .scroll-button {
    bottom: 55px;
  }

  .barretta-bianca {
    height: 70px;
  }

  .go-up {
    bottom: 0;
    right: 0;
  }
}
