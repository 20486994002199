#menu {
  position: fixed;
  top: 15px;
  right: 15px;
  width: 45px;
  height: 45px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s ease-in-out;
  color: white;
  z-index: 999;
  i {
    font-size: 32px;
  }
}

.menu-on-scroll {
  background-color: black;
}

#side-bar {
  position: fixed;
  top: 0;
  right: -280px;
  width: 280px;
  background-color: black;
  height: 100vh;
  z-index: 1000;
  transition: all .8s cubic-bezier(.77,.2,.05,1) ;
  color: white;
}

.close-menu {
  position: absolute;
  cursor: pointer;
  top: 5px;
  right: 5px;
  z-index: 1001;
  i {
    font-size: 24px;
  }
}

.orange {
  font-size: 12px;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  color: #f28e30;
  font-weight: 600;
}

.open-menu {
  right: 0 !important;
}

.menu-list {
  li {
    text-transform: capitalize;
    font-weight: 600;
    cursor: pointer;
    transition: color .3s ease-in-out;
    &.active {
      color: #f28e30;
    }
  }
}

.menu-social {
  i {
    font-size: 1.6rem;
    color: rgba(255, 255, 255, 0.3);
    transition: color 0.3s ease-in-out;
  }
  li {
    cursor: pointer;
  }

  li:hover i {
    color: white;
  }
}

.menu-lang {
  li {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.3);
    transition: color 0.3s ease-in-out;
    font-weight: 600;
  }
  .active {
    color: #f28e30 !important;
  }

  .list-inline-item:not(:last-child) {
    padding-right: 1rem !important;
  }
  li {
    cursor: pointer;
  }

  li:hover {
    color: white;
  }
}
